import React from 'react';
import { Link } from "gatsby"
import SEO from "../components/seo";
import Container from  '../components/container';
import styled from 'styled-components';
import Img from "gatsby-image";

const PostTemplate = (props) =>{
    return( 
            <section>
            <SEO title={props.pageContext.title} description={props.pageContext.intro} />
            <StyledImage fluid={props.pageContext.localImage.childImageSharp.fluid} alt={props.pageContext.title} />  
            <Container>
                <h2>{props.pageContext.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: props.pageContext.content }}></div>
                <Link to="/">Go back </Link>
            </Container>
            </section>
    )
}

const StyledImage = styled(Img)`
    height : 65vw;
    max-height: 88vh; 
    position: relative;
    margin-bottom: 3px; 
`

export default PostTemplate;